/**
 * Run a callback when DOM is ready (readyState is not loading).
 * @param callback
 */
export const onDomReady = (callback) => {
  if (document.readyState !== 'loading') {
    callback();
  } else {
    window.addEventListener('DOMContentLoaded', callback);
  }
};

/**
 * Run a callback when window is loaded (readyState is complete).
 * @param callback
 */
export const onWindowLoad = (callback) => {
  if (document.readyState === 'complete') {
    callback();
  } else {
    window.addEventListener('load', callback);
  }
};

/**
 * Check whether the device is a mobile.
 */
export const isMobile = () => {
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  return regex.test(navigator.userAgent);
}

/**
 * Check whether the page is being iframed.
 */
export const isInIframe = () => {
  return window.location !== window.parent.location;
  // return window.self !== window.top;
};

/**
 * The Knuth shuffle (a.k.a. the Fisher-Yates shuffle) is an algorithm
 * for randomly shuffling the elements of an array.
 */
export const knuthShuffle = (arr) => {
  var rand, temp, i;

  for (i = arr.length - 1; i > 0; i -= 1) {
      rand = Math.floor((i + 1) * Math.random()); //get random between zero and i (inclusive)
      temp = arr[rand];
      arr[rand] = arr[i]; //swap i (last element) with random element.
      arr[i] = temp;
  }
  return arr;
}

/**
 * Create a unique ID.
 */
export const uid = () =>
  String(
    Date.now().toString(32) +
      Math.random().toString(16)
  ); // .replace(/\./g, '');