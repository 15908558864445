import Component from 'gia/Component';
import eventbus from 'gia/eventbus';
import Cropper from 'cropperjs';
import appData from '../data.js';
 
class Croptool extends Component {
    cropper = null;

    constructor(element) {
        super(element);

        this.ref = {
            wrapper: null,
            preview: null,
            zoomSlider: null,
            ratioRadios: [],
            uploadBtn: null,
            downloadBtn: null,
        }

        this.cb = appData.cacheBuster;

        this.registerEventBus();
    }

    stateChange(stateChanges) {
        if (!this.cropper || !this.cropper.ready) return;

        if ('hotel' in stateChanges) {
            this.setCropperMask();
        }

        if ('colour' in stateChanges) {
            this.setCropperMask();
        }

        // if ('zoom' in stateChanges) {}
            
		if ('ratio' in stateChanges) {
		    this.cropper.setAspectRatio(this.state.ratio);
            this.setCropperMask();
        }
     }

    mount() {
        this.bindEventListeners();
        this.registerEventListeners();
    }

    /**
     * Register event bus
     */
    registerEventBus() {
        eventbus.on('selector::hotelChanged', (data) => this.setState({ hotel: data.value }));
        eventbus.on('selector::colourChanged', (data) => this.setState({ colour: data.value }));
        eventbus.on('dropzone::fileSelected', (data) => this.uploadFile(data.file));
    }

    /**
     * Bind only event listeners which need to be removed
     */
    bindEventListeners() {}

    /**
     * Register event listeners
     */
    registerEventListeners() {
        this.ref.zoomSlider.addEventListener('input', (evt) => this.onZoomSliderChange(evt));
        this.ref.ratioRadios.forEach((radio) => {
            radio.addEventListener('change', (evt) => this.onRatioRadiosChange(evt));

            if (radio.checked) {
                this.setState({ ratio: radio.value });
            }
        });

        this.ref.uploadBtn.addEventListener('click', (evt) => eventbus.emit('dropzone::openFileInput'));

        this.ref.downloadBtn.addEventListener('click', (evt) => this.mergeImages());

        window.addEventListener('resize', (evt) => this.resetZoomSlider());
    }

    uploadFile(file) {
		console.log(`File added: ${file.name}`, file);

        this.element.hidden = false;

		const reader = new FileReader();
		reader.onload = (evt) => {
			if (evt.target.result) {
				// Create new image
				const img = new Image;
				img.onload = () => {
					img.width = img.naturalWidth;
					img.height = img.naturalHeight;

					// Init cropper
					this.initCropper(img);
				};
                img.onerror = () => {
                    alert('Your image failed to load. Please try again.');
                }
				img.id = 'uploaded-image';
				img.src = evt.target.result;
			}
		};
		reader.readAsDataURL(file);
  	}

    initCropper(img) {
		// Reset
		this.resetZoomSlider();
		this.ref.wrapper.innerHTML = '';
		
        // Add new uploaded image
        this.ref.wrapper.appendChild(img);

		// Init cropper
		this.cropper = new Cropper(img, {
			responsive: true,
			restore: false,
			viewMode: 1,
			dragMode: 'move',
            toggleDragModeOnDblclick: false,
			aspectRatio: this.state.ratio,
			autoCropArea: 1,
			movable: true,
			zoomable: true,
			checkOrientation: true,
			rotatable: true,
			scalable: true,
			zoomOnWheel: true,
			zoomOnTouch: true,
			cropBoxMovable: false,
			cropBoxResizable: false,
			background: false,
			modal: true,
			highlight: false,
			center: false,
			guides: false,
			ready: (evt) => {
				window.cropper = this.cropper;
                setTimeout(() => {
                    this.cropper.resize();
                    this.setCropperMask();
                }, 0);

                eventbus.emit('croptool::imageLoaded');
			},
			/*crop: (evt) => {
				if (this.ref.preview) {
                    const imgSrc = this.cropper.getCroppedCanvas({
                        width: 400,
                        height: 500
                    }).toDataURL('image/png');
                    this.ref.preview.src = imgSrc;
                }
			},*/
            zoom: (evt) => {
                // console.log('Zoom', evt);
                if (evt.detail.ratio > Number(this.ref.zoomSlider.max)) {
                    evt.preventDefault();
                }

                this.setZoomSlider(evt.detail.ratio);
                this.setState({ zoom: evt.detail.ratio });
            }
		});
	}

    getCropperMaskFilename() {
        return `./frames/${this.state.hotel}/${this.state.ratio}/${this.state.colour}.png?v=${this.cb}`;
    }

    setCropperMask() {
        if (!this.state.hotel || !this.state.colour || !this.state.ratio) return;

        const imageSrc = this.getCropperMaskFilename();
        this.cropper.face.style.backgroundImage = `url(${imageSrc})`;
        // console.log(imageSrc);
        
        this.centerCropBox();
    }

    centerCropBox() {
        const containerData = this.cropper.getContainerData();
        const canvasData = this.cropper.getCanvasData();
        const cropboxData = this.cropper.getCropBoxData();

        const imageMaxLeft = (containerData.width - cropboxData.width) / 2;
        const imageMinLeft = imageMaxLeft - canvasData.width + cropboxData.width;
        // console.log(imageMaxLeft, imageMinLeft);
        
        if (canvasData.left > imageMaxLeft) {
            this.cropper.setCanvasData({ left: imageMaxLeft });
        } else if (canvasData.left < imageMinLeft) {
            this.cropper.setCanvasData({ left: imageMinLeft });
        }

        const imageMaxTop = (containerData.height - cropboxData.height) / 2;
        const imageMinTop = imageMaxTop - canvasData.height + cropboxData.height;
        // console.log(imageMaxTop, imageMinTop);

        if (canvasData.top > imageMaxTop) {
            this.cropper.setCanvasData({ top: imageMaxTop });
        } else if (canvasData.top < imageMinTop) {
            this.cropper.setCanvasData({ top: imageMinTop });
        }

        this.cropper.setCropBoxData({ left: imageMaxLeft, top: imageMaxTop });
    }

    onZoomSliderChange(evt) {
        this.cropper.zoomTo(evt.target.value);
	}

    setZoomSlider(zoom) {
        this.ref.zoomSlider.value = zoom;
        this.setState({ zoom });
    }

    resetZoomSlider() {
		this.setZoomSlider(0);
	}

    onRatioRadiosChange(evt) {
        this.setState({ ratio: evt.target.value });
	}

    mergeImages() {
        if (!this.cropper || !this.cropper.ready) return;

        const canvas = this.cropper.getCroppedCanvas({
            width: 1080,
            height: this.state.ratio == 1 ? 1080 : 1350
        });
        const ctx = canvas.getContext('2d');
        

        const frame = new Image();
        frame.onload = () => {
            // ctx.imageSmoothingEnabled = false;
            ctx.drawImage(frame, 0, 0, canvas.width, canvas.height);
            
            this.downloadImage(canvas);
            // document.body.appendChild(canvas);
        };
        frame.onerror = () => {
            alert('The frame image failed to load. Please try again.');
        };
        frame.src = this.getCropperMaskFilename();

        // https://imagekit.io/blog/how-to-resize-image-in-javascript/
        // https://www.npmjs.com/package/canvas-size
    }

    downloadImage(canvas) {
        const hotel = this.state.hotel;
        const colour = this.state.colour;
        const ratio = this.state.ratio == 1 ? 'square' : 'portrait';

        // const dataUrl = canvas.toDataURL('image/png');
        const dataUrl = canvas.toDataURL('image/jpeg', 1.0);
        const link = document.createElement('a');
        link.download = `${hotel}-${colour}-${ratio}.jpg`;
        link.href = dataUrl;
        link.click();
    }
}

export default Croptool;
