import Component from 'gia/Component';
import eventbus from 'gia/eventbus';
import TomSelect from 'tom-select';
 
class Selectors extends Component {
    constructor(element) {
        super(element);

        this.ref = {
            hotelSelect: null,
            colourSelects: [],
        }

        this.registerEventBus();
    }

    mount() {
        this.bindEventListeners();
        this.registerEventListeners();

        this.init();
    }

    /**
     * Register event bus
     */
    registerEventBus() {
        eventbus.on('croptool::imageLoaded', (data) => this.element.hidden = false);
    }

    /**
     * Bind only event listeners which need to be removed
     */
    bindEventListeners() {}

    /**
     * Register event listeners
     */
    registerEventListeners() {}

    async init() {
        const initPromises = [];

        const promiseHotel = new Promise((resolve, reject) => {
            new TomSelect(this.ref.hotelSelect, {
                maxItems: 1,
                controlInput: null,
                render: {
                    option: (data, escape) => {
                        return `<div class="flex items-center gap-3"><img class="size-5" src="${data.image}">${data.text}</div>`;
                    },
                    item: (item, escape) => {
                        return `<div class="flex items-center gap-3"><img class="size-5" src="${item.image}">${item.text}</div>`;
                    }
                },
                onInitialize: () => resolve(),
                onChange: (value) => this.onHotelSelectorChange(value)
            });
        });
        initPromises.push(promiseHotel);

        this.ref.colourSelects.forEach((select) => {
            const promiseColour = new Promise((resolve, reject) => {
                new TomSelect(select, {
                    maxItems: 1,
                    controlInput: null,
                    render: {
                        option: (data, escape) => {
                            return `<div class="flex items-center gap-3"><div class="w-8 h-4 tag ${data.colourClass ?? 'role'}"></div>${data.text}</div>`;
                        },
                        item: (item, escape) => {
                            return `<div class="flex items-center gap-3"><div class="w-8 h-4 tag ${item.colourClass ?? 'role'}"></div>${item.text}</div>`;
                        }
                    },
                    onInitialize: () => {
                        if (select.hidden) {
                            select.tomselect.wrapper.hidden = true;
                        }

                        resolve();
                    },
                    onChange: (value) => this.onColourSelectorChange(value)
                });
            });
            initPromises.push(promiseColour);
        });

        await Promise.all(initPromises);
        
        this.onHotelSelectorChange(this.ref.hotelSelect.tomselect.getValue());
    }

    onHotelSelectorChange(value) {
        this.ref.colourSelects.forEach((select) => {
            if (select.dataset.hotel == value) {
                select.hidden = false;
                select.tomselect.wrapper.hidden = false;
                eventbus.emit('selector::hotelChanged', { value });
                eventbus.emit('selector::colourChanged', { value: select.tomselect?.getValue() });
            } else {
                select.hidden = true;
                select.tomselect.wrapper.hidden = true;
            }
        });
	}

    onColourSelectorChange(value) {
        eventbus.emit('selector::colourChanged', { value });
	}
}

export default Selectors;
